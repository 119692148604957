import { Box, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingPage } from 'react-admin';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import EventsService, { setRepeatingEventNextDate } from '../services/Events';
import EventCard from './EventCard';
import { EventImage } from './EventsShared';
import { useEvents } from './Utils';
import EventMiniCard from './EventMiniCard';

const EventDetail = () => {
  const { id }: { id?: number } = useParams();
  const [event, setEvent] = useState<any>(null);
  const { events } = useEvents();

  useEffect(() => {
    if (!id) {
      return;
    }
    const fetchEvent = async () => {
      const eventData = await EventsService.getEvent(id);
      setEvent(setRepeatingEventNextDate(eventData));
    };
    fetchEvent();
  }, [id]);

  if (event === null) {
    return (
      <Box>
        <LoadingPage
          loadingPrimary="Loading..."
          loadingSecondary="Please wait while we load the event details!"
        />
      </Box>
    );
  }

  const getRandomEvents = (eventsArray, limit) => {
    const shuffled = eventsArray.sort(() => 0.5 - Math.random());
    return shuffled.slice(0, limit);
  };

  const upcomingEvents = events?.upcomingEvents ? getRandomEvents(events.upcomingEvents, 6) : [];

  const handleEventClick = (eventId) => {
    window.location.href = `/event/${eventId}`;
  };

  return (
    <Box mt={4} p={2}>
      <Helmet>
        <title>
          {event.title} at {event.venue?.name || ''}- RogueValley.Events
        </title>
        <meta name="description" content={event.description} />
        <meta
          name="keywords"
          content={`Rogue Valley, Southern Oregon, events, local events, ${
            event.title
          }, ${event.tags.join(',')}`}
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="RogueValley.Events" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`${event.title} - RogueValley.Events`}
        />
        <meta property="og:description" content={event.description} />
        <meta
          property="og:url"
          content={`https://roguevalley.events/event/${id}`}
        />
        <meta property="og:image" content={event.poster_image_url} />
        <meta property="og:site_name" content="RogueValley.Events" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${event.title} - RogueValley.Events`}
        />
        <meta name="twitter:description" content={event.description} />
        <meta name="twitter:image" content={event.poster_image_url} />
        <link rel="canonical" href={`https://roguevalley.events/event/${id}`} />
      </Helmet>
      <Paper>
        <Box
          display="flex"
          flexDirection={{ xs: 'column', sm: 'row' }}
          gap={2}
          p={2}
        >
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
            flex={1}
          >
            <EventImage event={event} flex={1} />
          </Box>
          <EventCard event={event} />
        </Box>
      </Paper>
      <Box mt={4}>
        <Typography variant="h6" gutterBottom>
          Other Upcoming Events
        </Typography>
        <Box
          sx={{
            columnCount: { xs: 1, sm: 2, md: 3 },
            columnGap: '16px',
          }}
        >
          {upcomingEvents.map((upcomingEvent) => (
            <Paper
              key={upcomingEvent.id}
              sx={{
                display: 'inline-block',
                width: '100%',
                marginBottom: '16px',
                breakInside: 'avoid',
                cursor: 'pointer',
              }}
              onClick={() => handleEventClick(upcomingEvent.id)}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                p={2}
              >
                <Box
                  component="img"
                  src={upcomingEvent.poster_image_url}
                  alt={upcomingEvent.title}
                  sx={{ width: '100%', height: 'auto', mb: 2 }}
                />
                <EventMiniCard event={upcomingEvent} showImage={false} />
              </Box>
            </Paper>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default EventDetail;
